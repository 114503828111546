











































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import CzUsersSearchBar from "@/components/cz-users-search-bar.vue";
import ListOfVenuesTableItem from "@/components/azVenues/ListOfVenuesTableItem.vue";
import {mapActions, mapGetters} from "vuex";
import filterCircleIcon from "@iconify-icons/ion/filter-circle";
import vSelect from "vue-select";

export default Vue.extend({
  components: {
    Layout,
    vSelect,
    "cz-users-search-bar": CzUsersSearchBar,
    "list-of-venues-table-item": ListOfVenuesTableItem,
  },

  data() {
    return {
      title: "List of venues",
      currentPage: 1,
      perPage: 10,
      userAuthorisationsVenues: [],
      activeFilter: false,
      randomId: Math.floor(Math.random() * 100000),
      userIsOwnerOfVenueOwner: false,
      icons: {
        home: filterCircleIcon,
      },
      city:"",
      country:"",
      status:"",
      type:"",
      mockVenues:[
        {
          "id": 1360,
          "name": "venue 1",
          "address": "address 1",
          "country": "country 1",
          "venueOwner": "venue owner 1",
          "status": 1,
          "type": "premium",
          "subscriptionFee": 19.90,
          "playlist": 'Cardio & Workout',
          "created_at": 1629363712,
        },
        {
          "id": 1361,
          "name": "venue 2",
          "address": "address 2",
          "country": "country 2",
          "venueOwner": "venue owner 2",
          "status": 1,
          "type": "basic",
          "subscriptionFee": 29.90,
          "playlist": 'Cardio & Workout',
          "created_at": 1629363712,
        },
        {
          "id": 1363,
          "name": "venue 3",
          "address": "address 3",
          "country": "country 3",
          "venueOwner": "venue owner 3",
          "status": 2,
          "type": "basic",
          "subscriptionFee": 39.90,
          "playlist": 'Cardio & Workout',
          "created_at": 1629363712,
        },
        {
          "id": 1364,
          "name": "venue 1",
          "address": "address 1",
          "country": "country 1",
          "venueOwner": "venue owner 1",
          "status": 2,
          "type": "premium",
          "subscriptionFee": 49.90,
          "playlist": 'Cardio & Workout',
          "created_at": 1629363712,
        },
        {
          "id": 1365,
          "name": "venue 1",
          "address": "address 1",
          "country": "country 1",
          "venueOwner": "venue owner 4",
          "status": 2,
          "type": "premium",
          "subscriptionFee": 49.90,
          "playlist": 'Cardio & Workout',
          "created_at": 1629363712,
        },
      ]
    }
  },

  // async created(): Promise<void> {
  //   await this.checkLoggedUserRolePermission({ permissionId: 11 });
  //   await this.checkLoggedUserRolePermission({permissionId: 12});
  // },

  async mounted(): Promise<void> {

  },

  computed: {

  },

  methods: {
    goToAddNewVenue() {
      this.$router.push("/az-create-venue");
    },
  },

})
