var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"row justify-content-between align-items-center pl-3"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_c('div',[_c('b-button',{staticClass:"blue-rounded-button btn-rounded mr-3",attrs:{"variant":"info"}},[_c('i',{staticClass:"bx bx-cloud-download"}),_vm._v(" Export")]),_c('b-button',{staticClass:"user-access-rights-button-new-user mr-2",on:{"click":_vm.goToAddNewVenue}},[_vm._v(" + "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('Create New Venue')))])])],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-8 search-col mb-4 pl-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('cz-users-search-bar',{staticClass:"w-50"})],1)]),_c('div',{staticClass:"col-xl-8 search-col d-flex justify-content-end"},[_c('div',[_c('iconify-icon',{attrs:{"color":'#828282',"icon":_vm.icons.home,"height":"32"}})],1),_c('div',{staticClass:"col-3"},[_c('v-select',{staticClass:"message-option",attrs:{"placeholder":_vm.$t('City'),"options":[
                   {label: ("" + (this.$t('City 1'))), value: 'City 1'},
                   {label: ("" + (this.$t('City 2'))), value: 'City 2'} ]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('div',{staticClass:"col-3"},[_c('v-select',{staticClass:"message-option",attrs:{"placeholder":_vm.$t('Country'),"options":[
                   {label: ("" + (this.$t('Country 1'))), value: 'Country 1'},
                   {label: ("" + (this.$t('Country 2'))), value: 'Country 2'} ]},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('div',{staticClass:"col-3"},[_c('v-select',{staticClass:"message-option",attrs:{"placeholder":_vm.$t('Status'),"options":[
                   {label: ("" + (this.$t('Status 1'))), value: 'Status 1'},
                   {label: ("" + (this.$t('Status 2'))), value: 'Status 2'} ]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',{staticClass:"col-3"},[_c('v-select',{staticClass:"message-option",attrs:{"placeholder":_vm.$t('Type'),"options":[
                   {label: ("" + (this.$t('Type 1'))), value: 'Type 1'},
                   {label: ("" + (this.$t('Type 2'))), value: 'Type 2'} ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)])]),_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-centered table-nowrap"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Name')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Address')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Country')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Venue Owner')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Status')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Type')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Subscription fee')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Playlist')))]),_c('th',{staticClass:"table-header"},[_vm._v(_vm._s(_vm.$t('Registration date')))]),_c('th',{staticClass:"table-header"})])]),_vm._l((_vm.mockVenues),function(venue){return _c('list-of-venues-table-item',{key:venue.id,attrs:{"venue":venue}})})],2)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }